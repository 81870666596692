<template>
  <div class="dialog">
    <el-dialog title="上传文件（说明：选取文件->上传到服务器）" :visible.sync="dialogVisible" width="40%" :before-close="close">
      <!-- 上传文件 -->
      <el-upload
      class="upload-demo"
      ref="upload"
      :action="ossData.host"
      :data="ossData"
      :on-success="successUpload"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :file-list="fileList"
      :on-change="uploadSectionFile"
      :on-exceed="limitCheck"
      :before-upload="beforeAvatarUpload"
      :limit="limit"
      :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        <div slot="tip" class="el-upload__tip">可上传（image/png，image/gif，image/jpeg，image/jpg，audio/mpeg， video/mp4）文件，且不超过5个G</div>
        <div slot="tip" class="el-upload__tip">需上传{{limit}}个文件</div>
      </el-upload>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getKey } from '../request/api'

export default {
  props: ['dialogVisible', 'limit', 'video_id'],
  data () {
    return {
      fileList: [],
      ossData: {
        OSSAccessKeyId: '',
        policy: '',
        Signature: '',
        key: '',
        host: '',
        dir: ''
      },
      picAddress: [],
      ranNumber: Math.random()
    }
  },
  watch: {
    dialogVisible (newData) {
      if (newData) {
        this.picAddress = []
      }
    }
  },
  methods: {
    // 自定义事件 修改父组件中变量
    close () {
      this.$refs.upload.clearFiles()
      this.$emit('changeDialog')
    },
    confirm () {
      this.$refs.upload.clearFiles()
      this.$emit('getPic', this.picAddress, this.limit, this.video_id)
    },
    // 点击上传按钮触发的事件
    submitUpload() {
      this.$refs.upload.submit();
    },
    // 文件列表移除文件时的钩子
    handleRemove(file) {
      let fileLen = file.name.length
      this.picAddress.forEach((val, index) => {
        let substr = val.slice(-fileLen)
        if (substr === file.name) {
          this.picAddress.splice(index, 1)
        }
      })
    },
    beforeRemove (file) {
      return this.$confirm(`确定移除${file.name}？`)
    },
    // 获取oss签名
    async beforeAvatarUpload (file) {
      const res = await getKey()
      this.ossData.OSSAccessKeyId = res.data.accessid
      this.ossData.policy = res.data.policy
      this.ossData.Signature = res.data.signature
      this.ossData.key = res.data.dir + this.ranNumber + "${filename}"
      this.ossData.host = res.data.host
      this.ossData.dir = res.data.dir
      return file
    },
    // 上传成功的回调
    successUpload (res, file) {
      this.picAddress.push(this.ossData.host + '/' + this.ossData.dir + this.ranNumber + file.name)
      this.$message({
        message: '恭喜您，文件上传成功',
        type: 'success'
      })
    },
    // 文件状态改变时执行
    uploadSectionFile (file) {
      const typeArr = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg', 'audio/mpeg', 'video/mp4', 'application/pdf']
      const isJPG = typeArr.indexOf(file.raw.type) !== -1
      const isLt50M = file.size / 1024 / 1024 < (1024 * 5)
      if (!isJPG) {
        this.$message.error('只能上传指定格式文件！')
        this.$refs.upload.clearFiles()
        return
      }
      if (!isLt50M) {
        this.$message.error('上传文件不能超过5个G！')
        this.$refs.upload.clearFiles()
        return
      }
    },
    // 文件超出个数限制时的钩子
    limitCheck () {
      this.$message.warning(`每次只能上传${this.limit}个文件`)
    }
  }
}
</script>

<style>

</style>