<template>
  <div class="chain-details">
    <!-- 书签 -->
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/aiHome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/aiChain/list' }">学校列表</el-breadcrumb-item>
        <el-breadcrumb-item>编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form :model="chainForm" :rules="rules" ref="rulForm">
        <div class="form-top">
          <div class="form-compo">
            <div class="form-compo-div">
              <el-form-item label="学校名称" prop="chain_name">
                <el-input class="form-input" size="small" v-model="chainForm.chain_name"></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="到期时间" prop="validity">
                <el-input class="form-input" size="small" v-model="chainForm.validity" disabled></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="form-pic-title">学校logo</div>
          <div class="form-pic-desc">
            <el-form-item prop="pic">
              <el-image v-if="showImg" class="pic-img" :src="chainForm.pic"></el-image>
            </el-form-item>
            <div class="avatar-uploader">
              <i class="el-icon-plus avatar-uploader-icon" @click="addPic"></i>
            </div>
          </div>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm">确定</el-button>
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- 弹窗 -->
    <MyDialog :dialogVisible="dialogVisible" @changeDialog="changeDialog" @getPic="getPic" :limit="limit" />
  </div>
</template>

<script>
import MyDialog from '../../../../components/MyDialog.vue'
import { aiChainDetails, aiAddChain } from '../../../../request/api'

export default {
  components: {
    MyDialog
  },
  data() {
    return {
      chainForm: {
        chain_name: '',
        pic: '',
        shop_user_ai_chain_id: '',
        validity: ''
      },
      rules: {
        chain_name: [
          { required: true, message: '请输入学校名称', trigger: 'blur' }
        ]
      },
      showImg: false,
      // 弹窗
      dialogVisible: false,
      // 限制图片数量
      limit: 0
    }
  },
  methods: {
    // 上传机构logo 限制1张
    addPic() {
      this.limit = 1
      this.dialogVisible = true
    },
    async submitForm() {
      this.$refs.rulForm.validate(async (valid) => {
        if (valid) {
          if (this.chainForm.chain_name === '' ||
            this.chainForm.pic === ''
          ) {
            return this.$message.warning('请完善字段信息')
          }

          try {
            const res = await aiAddChain(this.chainForm)
            if (res.status !== 200 || res.data.code !== "1000") {
              return this.$message.error(res.data.msg);
            }
            this.$message.success('添加成功')
            this.$router.push({ path: '/aiChain/list' })
          } catch (error) {
            this.$message.error('提交失败');
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 返回
    resetForm() {
      this.$router.back()
    },
    // 取消按钮 关闭弹窗
    changeDialog () {
      this.dialogVisible = false
    },
    // 组件保存图片的回调
    getPic (picArr, id) {
      this.dialogVisible = false
      picArr.forEach((pic) => {
        if (id === 1) {
          this.chainForm.pic = pic
          this.showImg = true
        }
      })
    },
    // 初始化操作
    async init() {
      const res = await aiChainDetails(this.chainForm.shop_user_ai_chain_id)
      if (res.status !== 200 || res.data.code !== "1000") {
        return this.$message.error(res.data.msg);
      }
      const apiData = res.data.data
      this.chainForm.chain_name = apiData.chain_name
      this.chainForm.pic = apiData.pic
      this.chainForm.validity = apiData.validity
      this.showImg = true
    }
  },
  created() {
    this.chainForm.shop_user_ai_chain_id = this.$route.query.shop_user_ai_chain_id
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.chain-details {
  padding: 10px;
  .title {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .myform {
    .form-top {
      background: #fff;
      padding: 22px;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      .form-compo {
        display: flex;
        .form-compo-div {
          width: 340px;
          .form-input {
            width: 242px;
          }
        }
      }
      .form-pic-title {
        border-bottom-style: solid;
        border-bottom-color: #c4c4c4;
        border-bottom-width: 2px;
        width: 646px;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
      }
      .form-pic-desc {
        display: flex;
        align-items: center;
        .avatar-uploader {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 120px;
            height: 120px;
            line-height: 120px;
            text-align: center;
          }
        }
        .avatar-uploader:hover {
          border-color: #409eff;
        }
        .pic-img {
          width: 120px;
          height: 120px;
          margin-right: 10px;
        }
      }
    }
    .form-button {
      background: #fff;
      padding: 20px 0px 2px 20px;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 10px 0px;
    }
  }
}
</style>